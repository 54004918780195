.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust based on your needs, this takes the full height */
    width: 100%; /* This takes the full width */
    position: absolute; /* Overlay on top of your content */
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5); /* Light white background */
  }
  
  .loader {
    border: 6px solid #f3f3f3; /* Light grey */
    border-top: 6px solid #3498db; /* Blue color */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
form{
box-sizing: border-box;
padding: 50px;
display: flex;
flex-direction: column;
gap: 20px;
}

ul{
display: flex;
flex-direction: column;
}

.quill{
height: 400px ;
margin-bottom: 200px;
}


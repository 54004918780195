.rehab-item {
    text-align: center;
    padding: 10px;
    margin: 0px;

    display: flex;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    min-width: 100%;
    box-sizing: border-box;
  }

  .prodimg{
  max-height: 100% !important;
  object-fit: scale-down !important;
  max-width: 100% !important;
  min-width: 2px !important;
  }


  .rehab-carousel .viewsproduct{
color: #ffffff ;
background-color: rgb(0, 55, 255);
  }


  .slick-next,
.slick-prev {
  display: none !important;
}
  

.rehab-carousel{
    border-radius: 10px;
    height: 500px;
    box-sizing: border-box;
}

.buylink{
background-color: #1650A6!important;
padding: 10px;
border-radius: 5px;
color: white;
cursor: pointer;
}

.vidlink{
box-sizing: border-box;
padding: 20px;
margin: 15px;
border-radius: 5px;
box-shadow: 10px 10px 20px 5px rgba(59, 86, 183, 0.096);
}

.vidlink iframe{
border-radius: 5px;
}

.topin{
display: flex;
justify-content: center;
align-items: center;
gap: 30px;
}

.productconec{
  box-shadow: 10px 10px 20px 10px rgba(59, 86, 183, 0.096);
margin: 40px;
border-radius: 5px;

height: 300px;
width: auto;
display: flex;
gap: 30px;
box-sizing: border-box;
padding: 30px;
align-items: center;
flex-direction: column;
justify-content: center;
}

  
  .rehab-carousel video {
    max-width: 250px;
    min-width: 250px;
    border-radius: 10px;
    object-fit: scale-down;
    border-radius: 8px;
    margin-bottom: 30px;
    box-sizing: border-box;
  }

  .shopitem{
 display: flex;
 width: 100%;
 padding: 20px;
 box-sizing: border-box;
 align-items: center;
 justify-content: space-between;
  }

  .dog{
display: flex;
flex-direction: column;
text-align: start;
  }
  
  .rehab-carousel h3 {
    color: rgba(0, 26, 74, 0.677) ;
    font-size: 1.5em;
    margin: 0px;
  }
  
  .rehab-carousel p {
    color:   rgba(0, 26, 74, 0.394) ;
    margin-bottom: 20px;
  }
  
  .rehab-carousel a {
    text-decoration: none;
    background-color:rgb(0, 26, 74) ;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }

  .itemtitles{
  background-color: white;
  color: #4873B4 !important;
  }
  
  .rehab-carousel a:hover {
    background-color: #0056b3;
  }

  /* Override the default slick-dots styles */
.slick-dots {
    display: flex;
    justify-content: space-between;
    gap: 50px;
  
    padding: 0px ;
    width: 100%;
    list-style: none;
    
  }
  
  .slick-dots li {
    margin: 0 10px;
  /* Adjust the space between dots */
  }
  
  .slick-dots li button {
    border: none;
    background: none;
  }
  
  /* Style for all dots */
  .slick-dots li button:before {
    font-size: 16px; /* Adjust the size of the dots */
    line-height: 20px;
    width: 20px; /* Width of the dot */
    height: 20px; /* Height of the dot */
    border-radius: 50%; /* Makes it circular */
    background-color: rgba(0, 26, 74, 0.398); /* Color of the dots */
    opacity: 1; /* Fully opaque by default */
    content: ''; /* Removes the content inside before pseudo element */
    display: inline-block; /* Aligns with the rest of the inline elements */
  }
  
  /* Style for the active dot */
  .slick-dots li.slick-active button:before {
    background-color:     rgb(0, 26, 74);; /* Color of the active dot */
  }

@media (max-width: 500px) {

  .vidlink{
    box-sizing: border-box;
    padding: 20px;
    margin: 20px;
    border-radius: 5px;
    box-shadow: 10px 10px 20px 5px rgba(59, 86, 183, 0.096);
    }

    .productconec{
      box-shadow: 10px 10px 20px 10px rgba(59, 86, 183, 0.096);
    margin: 20px;
    border-radius: 5px;
    
    height: 300px;
    width: auto;
    display: flex;
    gap: 30px;
    box-sizing: border-box;
    padding: 30px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    }


  
  .rehab-carousel video {
    max-width: 250px;
    min-width: 250px;
    border-radius: 10px;
    object-fit: scale-down;
    border-radius: 8px;
    box-sizing: border-box;
  }
}
  
  
.app-footer {
    background-color: white;
    color: #0A3C86;
    padding: 20px 0;
    font-size: 14px;
    z-index: -1;
    border-top: 0.5px solid rgba(0, 0, 255, 0.136);
  }
  
  .footer-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    padding: 20px;
  }
  
  .footer-section h4 {
    color: #ffffff5c;
    margin-bottom: 10px;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
  }

  .footer-section p{
  color: #0A3C86;
  width: 40ch;
  }
  
  .footer-section ul li a {
    color: #0A3C86 !important;
    text-decoration: none;
    font-weight: bold;
  }
  
  .footer-section ul li a:hover {
    text-decoration: underline;
  }
  
  .footer-bottom {
    text-align: center;
    border-top: 1px solid #ffffff00;
    padding-top: 10px;
    margin-top: 10px;
  }
  
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  
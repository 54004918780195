body{
margin: 0px;
padding: 0px;
}


  

.vid4{
position: absolute;
right: 60px;
z-index: -3;
}

.ctabox{
height: 90vh;
max-width: 100vw;
gap: 60px;
display: flex;
overflow: hidden;
box-sizing: border-box;
padding: 30px;
flex-direction: column;
align-items: center;
justify-content: center;
}

.tryimage{
position: absolute;
height: 100%;
opacity: 0.1;
top: 100px;
left: 0px;
z-index: -1;
}

.topText{
display: flex;
flex-direction: column;
gap: 30px;
}

.topText h1{

padding: 0px;
line-height: 65px;
font-size: 50px;
color: #1650A6;
width: fit-content;
margin: 0px;
width: 25ch;
}

.topText p{

    padding: 0px;
    color: #1650A6;
    margin: 0px;
    width: 50ch;
   line-height: 33px;
    }

    .ctaButtons{
    display: flex;
    gap: 20px;
    align-self: flex-start;

    }

    .infobok{
    display: flex;
    flex-direction: column;
    gap: 60px;
    

    }

    .injuryLink{
    color: white;
    cursor: pointer;
    background-color: #1650A6;
    padding: 30px;
    border-radius: 5px;
    text-decoration: none;
    list-style: none;
    }

    .injuryLink:hover{
        background-color: #1650a6d6;
    }

    .shopLink{
        color: #1650A6;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
    list-style: none;
        background-color: white;
        box-shadow: 10px 10px 20px 10px rgba(128, 128, 128, 0.2);
        padding: 20px;
        border-radius: 5px;
        }

        @media (max-width: 1300px) {
            .tryimage{
                position: absolute;
                height: 300px;
                opacity: 0.1;
                top: 200px;
                left: 0px;
                z-index: -1;
                }
        }

     

        @media (max-width: 1000px) {
            .vid4{
                opacity: 0.3;
                height: 400px;
                }

                .topText h1{

                    padding: 0px;
                    line-height: 65px;
                    font-size: 50px;
                    color: #1650A6;
                    width: fit-content;
                    margin: 0px;
                    width: 20ch;
                    }

                

                .tryimage{
                    position: absolute;
                    height: 300px;
                    opacity: 0.1;
                    top: 200px;
                    left: 0px;
                    z-index: -1;
                    }
        }
        @media (max-width: 700px) {


            .topText h1{   width: 15ch;}


        }
        @media (max-width: 500px) {
            .topText h1{
        
                padding: 0px;
                font-size: 30px;
                line-height: 35px;
                color: #1650A6;
                width: fit-content;
                margin: 0px;}

                .vid4{
                   
                        opacity: 0.2;
                        height: 200px;
                        
                    }

                .topText p{
                
                    padding: 0px;
                    color: #1650A6;
                    margin: 0px;
                    font-size: 15px;
                    width: 30ch;}


.tryimage{
    position: absolute;
    height: 300px;
    opacity: 0.1;
    top: 200px;
    left: 0px;
    z-index: -1;
    }
        }
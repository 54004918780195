.godgiven{
min-width: 100%;
height: fit-content;
max-width: 100%;
margin-top: 1px;
display: none;
opacity: 0.7;
box-shadow: 10px 10px 20px 10px rgba(0, 67, 251, 0.04);
}

.overlayd {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999; /* Ensure it's below the popup but above everything else */
  }
  
  .blurred {
    filter: blur(2px);
    pointer-events: none; /* Makes elements unclickable */
  }

.loading-screen {
    background-color: white; /* Background color */
    display: flex;
    color: rgb(16, 30, 154);
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: fixed; /* Use fixed to ensure it covers the whole screen even when scrolled */
    top: 0;
    left: 0;
    width: 100%; /* Full width */
    height: 100vh; /* Full viewport height */
    z-index: 9999; /* Ensure it's on top of other content */
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 20px 40px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 3px;
    z-index: 1000;
    width: auto;
    display: flex;
    flex-direction: column;
    z-index: 9999;
    gap: 30px;
    max-width: 90%; /* Ensure it doesn't get too wide on large screens */
    box-sizing: border-box;
  }

  .ausonly{
height: 50px;
width: auto;
  }
  
  .popup p {
    color: #333;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    margin: 0 0 0px 0; /* Add space between the text and the button */
    text-align: center;
  }
  
  .popup button {
    background-color: #4873B4;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    cursor: pointer;
    display: block; /* Use block to make it easier to center */
    margin: 0 auto; /* Center the button horizontally */
    transition: background-color 0.3s ease;
  }
  
  .popup button:hover {
    background-color: #0056b3;
  }
  

@media only screen and (max-width: 900px) {
    .godgiven{
        min-width: 100%;
        height: 200px;
        object-fit: contain;
        max-width: 100%;
        margin-top: 1px;
        display: none;
        opacity: 0.7;
        box-shadow: 10px 10px 20px 10px rgba(0, 67, 251, 0.04);
        }
}


@media only screen and (max-width: 500px) {
    .godgiven{
        min-width: 100%;
        height: 200px;
        object-fit: contain;
        max-width: 100%;
        margin-top: 1px;
        display: none;
        opacity: 0.7;
        box-shadow: 10px 10px 20px 10px rgba(0, 67, 251, 0.04);
        }
        
}

.productimaged{
    object-fit: scale-down;
    max-width: 300px;
    max-height: 300px;
    min-width: 300px;
    min-height: 300px;
    border-radius: 3px;
    z-index: -1;
    }

    .image-coin{
    height: fit-content;
    }
    
    .description {
        transition: max-height 0.3s ease-out;
        overflow: hidden;
        margin-bottom: 30px;
      }
      
      .minimized {
        max-height: 0;
      }
      
      .expanded {
        max-height: 1000px; /* Adjust based on your content's needs */
      }
      
      .toggle-description {
        cursor: pointer;
        background-color: white;
        width: 80%;
        color: #4873B4;
        border-top: solid 1px rgba(0, 0, 255, 0.224);
        border-bottom: solid 1px rgba(0, 0, 255, 0.124);
        padding: 20px;
        display: flex;
        justify-content: space-between;
        border-radius: 0px;
        margin-bottom: 40px;
      }

      .toggle-description:hover{
    background-color: white;
      }

    .quantity-selector{
    background-color: white;
    box-sizing: border-box;
    padding: 10px;
    width: fit-content;
    border: solid 1px blue;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    }

    .handlingitems{
    display: flex;
    gap: 20px;
    }

    .quantity-button{
    background-color:white;
    color: blue;
    padding: 0px;
    margin: 0px;
    width: 85px;
    height: 100%;
    cursor: pointer;
    }

    .quantity-value{
    font-size: 15px;
    font-weight: bold;
    }

    .quantity-button:hover{
    background-color: white;
    }

    .productPrice{
    font-weight: lighter;
    color: #4873B4;
    font-weight: bold;

    }

 

    .text{
     box-sizing: border-box;
     padding: 30px;
    height: 100%;
    width: 100%;
    font-size: 15px !important;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    color: blue;
    }

    .loading-screen {
        background-color: white; /* Background color */
        display: flex;
        color: rgb(16, 30, 154);
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
        position: fixed; /* Use fixed to ensure it covers the whole screen even when scrolled */
        top: 0;
        left: 0;
        width: 100%; /* Full width */
        height: 100vh; /* Full viewport height */
        z-index: 9999; /* Ensure it's on top of other content */
    }

    .briefdes{
    max-width: 65ch;
    }


    .detailed{
    display: flex;
    height: 150vh;
    justify-content: flex-start;
    align-items: center;

    padding: 0px;
    box-sizing: border-box;
    }

    .productbutton{
    margin: 0px;
    background-color: #1650A6;
    }

    .addmore{
    border: solid 1px blue;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: blue;

    display: flex;
    padding: 10px;
    width: 80px
    }

    .productdescriptor{
    color: #4873B4;
    width: 100ch;
    max-width: 100%;
    height: 100%;
    }

    .productname{
        background-color: rgba(255, 255, 255, 0);
        padding: 0px;
        margin: 0px;
        color: #0A3C86;
        width: fit-content;
    }

    .description-segment {
        display: block; /* Make span behave like a block element to accept margin */
        margin-bottom: 20px; /* Adjust spacing as needed */
      }

    .imaged{
    display: grid;
  grid-template-columns: 1fr; /* Single column layout */
  gap: 10px; /* Space between images */
  height: 100%;
 overflow: scroll;
 justify-content: center;
 padding: 40px;
 width: 100%;

box-shadow: 5px 10px 20px 10px rgba(89, 100, 199, 0.106);
box-sizing: border-box;
    }

    @media (max-width: 1400px){
        .detailed{
            display: flex;
       height: 100%;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 10px;
            padding: 20px;
            } }

    @media (max-width: 768px){
        .detailed{
            display: flex;
       height: 100%;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 10px;
            padding: 20px;
            }
            .productdescriptor{
           
                width: 30ch;
                }

                

            .text{
                box-sizing: border-box;
                padding: 30px;
               height: 100%;
               width: 100%;
               display: flex;
               align-items: flex-start;
               justify-content: center;
               flex-direction: column;
               color: blue;
               box-shadow: 5px 10px 20px 10px rgba(89, 100, 199, 0.106);
               }

               .productdescriptor{
           
                width: 50ch;
                }
    
                .productimaged{
                    object-fit: scale-down;
                    max-width: 150px;
                    max-height: 150px;
                    min-width: 150px;
                    z-index: 1;
                    min-height: 150px;
                    border-radius: 3px;
                    }
                
    
    
        .imaged{
            display: flex;
         flex-direction: row; /* Single column layout */
     /* Space between images */
         height: fit-content;
         overflow: scroll;
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        background-color: white;
        box-shadow: 5px 10px 20px 10px rgba(89, 100, 199, 0.106);
            }
    
            .productdescriptor{
            font-size: 10px;
            }
    
    }


    @media (max-width: 500px){
        .productdescriptor{
   
            width: 50ch;
            }


    .quantity-selector{
        background-color: white;
        box-sizing: border-box;
        padding: 5px;
        width: fit-content;
        border: solid 1px blue;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        }



      .toggle-description {
        cursor: pointer;
        background-color: white;
        width: 90%;
        color: #4873B4;
        border-top: solid 1px rgba(0, 0, 255, 0.224);
        border-bottom: solid 1px rgba(0, 0, 255, 0.124);
        padding: 20px;
        display: flex;
        justify-content: space-between;
        border-radius: 0px;
        margin-bottom: 40px;
      }

            .productimaged{
                object-fit: scale-down;
                max-width: 150px;
                max-height: 150px;
                min-width: 150px;
                z-index: 1;
                min-height: 150px;
                border-radius: 3px;
                }
            


    .imaged{
        display: flex;
     flex-direction: row; /* Single column layout */
 /* Space between images */
      height: 300px;
     overflow: scroll;
    width: 100%;
    justify-content: flex-start;
    box-sizing: border-box;
    background-color: white;
    box-shadow: 5px 10px 20px 10px rgba(89, 100, 199, 0.106);
        }

        .productdescriptor{
        font-size: 10px;
        }


        .detailed{
            display: flex;
            height: auto;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            padding: 20px;
            gap: 10px;
            }


            .text{
                box-sizing: border-box;
                padding: 30px;
               height: 100%;
               width: 100%;
               display: flex;
               align-items: flex-start;
               justify-content: center;
               flex-direction: column;
               color: blue;
               box-shadow: 5px 10px 20px 10px rgba(89, 100, 199, 0.106);
               }
    
    }
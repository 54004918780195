.navigation-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(255, 255, 255); /* Match the color to the screenshot */
    padding: 10px;
    height: 10vh;
    z-index: 10000000000000000000000000000;
    border-bottom: 0.5px solid rgba(0, 0, 255, 0.136);

}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    pointer-events: none;
    visibility: hidden; /* Initially hidden, but still takes up space */
    opacity: 0;
    transition: visibility 0s 0.2s, opacity 0.2s ease; /* Delay hiding visibility */
  }
  
  .showOverlay {
    visibility: visible; /* Make visible */
    opacity: 1;
    transition: opacity 0.5s ease; /* No delay needed when showing */
  }
  

.weblogo{
min-width: 70px;
min-height: 70px;
object-fit: scale-down;
cursor: pointer;
opacity: 0.8;
}



.weblogo:hover{
opacity: 0.6;
    }

.shoppingboss{
display: flex;
gap: 30px;
align-items: center;
box-sizing: border-box;
}

.cart-container{
width: 30%;
top: 0px;
z-index: 100000000000000;
position: fixed;
transition: right 0.3s ease-in-out; 
right: -100%;
box-shadow: 10px 10px 20px 10px rgba(128, 128, 128, 0.294);
overflow: scroll;
}

nav{
z-index: 100000000;
}

.shopicon{
padding: 11px;
border-radius: 5px;
color: rgba(9, 60, 156, 0.468);

}

.blogicon{

    padding: 11px;
    border-radius: 5px;
    color: rgba(9, 60, 156, 0.468);
  
    }

.nav-list {
    list-style-type: none;
    display: flex;
    margin: 0;
    box-sizing: border-box;
    align-items: center;
    flex-direction: row;
    padding: 0;
    justify-content: space-between;
    width: 100%;
    z-index: 10000000;
}

.cartItem{
margin-right: 5px;
}



.nav-item img {
   height: 20px;
   width: 20px;
}

.nav-item a {
    text-decoration: none;
/* Text color */
}

.menu-icon {
    display: none;
    color: #484848;
}

.cart-icon{
    cursor: pointer;
    color: rgba(9, 60, 156, 0.468);
    border: solid 1px #1650A6;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
}

@media (max-width: 768px){
    
    .cart-container{
        width: 60%;
        top: 0;
        z-index: 9999;
        position: fixed;
        transition: right 0.31s ease-in-out; 
        right: -100%;
        }
}


@media (max-width: 500px){
    
    .cart-container{
        width: 70%;
        top: 0;
        z-index: 100000000000;
        position: fixed;
        transition: right 0.31s ease-in-out; 
        right: -100%;
        }

        .weblogo{
            min-width: 70px;
            min-height: 70px;
            object-fit: scale-down;
            cursor: pointer;
            }
            
}

/* Example of a media query to show the hamburger menu icon on smaller screens */

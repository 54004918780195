body{
color: black;
padding: 0px;
margin: 0px;
}

.dashboard{
width: 100%;
height: 60px;
display: flex;
align-items: center;
color: darkblue;
box-sizing: border-box;
padding-left: 50px;
padding-top: 30px;
justify-content: flex-start;
}

.productcount span{
font-weight: bold;
}

.productcount{
border-radius: 4px;
padding: 10px;
display: flex;
display: none;
gap: 5px;
flex-direction: column;

color: rgb(7, 16, 117);

box-shadow: 5px 10px 20px 10px rgba(89, 100, 199, 0.119);
}


.product-list{
display: grid;
align-content: center;
gap: 20px;
padding: 50px;
background-color: rgba(255, 255, 255, 0.863);
margin: 0px;
box-sizing: border-box;
grid-template-columns: 1fr 1fr 1fr 1fr;
}


.product h1, .product h3{
background-color: white;
color: #0A3C86 ;
padding: 0px;
font-weight: bold;
margin-top: 15px;
align-self: flex-start;
}

.product{
width: 100%;
display: flex;
flex-direction: column;
padding: 20px;
box-shadow: 5px 10px 20px 10px rgba(89, 100, 199, 0.106);
border-radius: 3px;
background-color: white;
box-sizing: border-box;
justify-content: center;
cursor: pointer;
align-items: center;
transition: all 0.2s;
}
.product:hover{
scale: 0.98;
}


.product a{
text-decoration: none;
list-style: none;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}

.product p{
margin: 0px;
padding: 0px;
}

.product img{
object-fit: scale-down;
width: 80%;
height: auto;
min-width: 5px;

}

.price{
color:  #4873B4 ;
text-decoration: none;
list-style: none;

}

.product-details{
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: center;
max-width: 100%;
min-width: 100%;
}

@media only screen and (max-width: 700px) {
    .product-list{
        display: grid;
        align-content: center;
        gap: 20px;
        margin: 20px;
        padding: 0px;
        grid-template-columns: 1fr 1fr;
        } 
}

@media only screen and (max-width: 500px) {
    .product-list{
        display: grid;
        align-content: center;
        gap: 15px;
        background-color: rgba(255, 255, 255, 0.863);
        margin: 0px;
        padding: 20px;
        box-sizing: border-box;
        grid-template-columns: 1fr 1fr ;
        }
}

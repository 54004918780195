@import 'https://js.stripe.com/v3/';


body{
margin: 0px;
padding: 0px;
}

.vec{
height: auto;
max-width: 100%;
}

.paymentcont{
display: flex;
height: 90vh;
justify-content: center;
align-items: center;
overflow: hidden;
max-width: 100vw;
}



.leftpayment{

height: 100%;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
background-color: #1650A6;
}

.leftpayment h2{
color: white;
font-size: 50px;
align-self: flex-start;
}

.leftpayment form{
width: 80%;
}

.rightpayment{
  height: 100%;
  width: 100%;
  }



.paySub{
align-self: flex-start;
background-color: rgb(58, 83, 245);
}

/* Target the CardElement container */
.StripeElement {
    display: block;
    padding: 10px;
    border:solid 1px blue;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
    background-color: white;
    box-sizing: border-box;

  }

  .cardInputs{
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 30px;
  box-shadow: 5px 10px 20px 10px rgba(128, 128, 128, 0.106);
  box-sizing: border-box;
  padding: 30px;
  border-radius: 5px;
  background-color: white;

  }

  .cardName{
width: 100%;
border: solid 1px blue;
padding: 10px;
box-sizing: border-box;
  }
  
  /* Style when it's focused */
  .StripeElement--focus {
    border-color: #0074cc;
  }

  
  /* Style when it's invalid */
  .StripeElement--invalid {
    border-color: #ff3860;
  }
  


.ElementsApp{
display: flex;
flex-direction: column;
background-color: red;
}

@media (max-width: 900px) {
  .paymentcont{
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    }

    .vec{
      height: auto;
      width: 100%;
      }


    .rightpayment{
      height: 50vh;
      width: 100%;
      overflow: hidden;
      background-color: white;
      }
}

@media (max-width: 500px) {
  .paymentcont{
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    }

    .vec{
      height: auto;
      width: 100%;
      }


    .rightpayment{
      height: 50vh;
      width: 100%;
      overflow: hidden;
      background-color: white;
      }


.leftpayment form{
  width: 100%;
  padding: 30px;
  }

}
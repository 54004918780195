.cart {
    width: 100%;
    box-shadow: 10px 10px 20px 10px rgba(53, 82, 187, 0.306);
    background-color: #ffffff;
    color: rgb(255, 255, 255);
    display: flex;
    box-shadow: 10px 10px 20px 10px rgba(128, 128, 128, 0.106);
    overflow: scroll;
    height: 100%;
    height: 100vh; /* Use viewport height for full height */
    box-sizing: border-box;
    margin: 0px;
    padding: 30px;
    position: relative;
    flex-direction: column;
    align-items: center;
    padding-bottom: 300px;
  }
  .addup p{
  color: rgb(13, 13, 13);
  font-weight: bold;
  }

  .calcto{
  display: flex;
  justify-content: space-between;
  width: 80%;
  }

  .addup{
  position: fixed;
  overflow: hidden;
  background-color:white;
  border-top: solid 1px rgba(0, 0, 255, 0.301);
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 30%;
  min-width: 30%;
  box-sizing: border-box;
  padding: 10px;
  
  }

  .carttop{
  border-bottom: solid 1px rgba(0, 0, 255, 0.222);
  width: 100%;

  font-size: 10px;
  }

  .cart h2{
  color:#1650A6 !important;
  }
  .itemname{
color: #1650A6;
background-color: rgba(255, 255, 255, 0);
padding: 0px;
margin: 0px;
border-radius: 0px;
  }
  /* Add JavaScript class to body to disable scrolling */
  .cart-open {
    overflow: hidden;
  }
  
  /* Your other CSS classes remain the same */
  .removal {
    background-color:rgb(255, 255, 255);
    box-shadow: 10px 10px 20px 10px rgba(128, 128, 128, 0.163);
    color: rgb(0, 0, 107);

  }
  
  .cartier {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  
    padding: 30px;
    border-bottom: 1px solid  rgb(222, 222, 222);
  }
  
  .boss {
    background-color: 
    #006bd6;
    color: white;
    padding: 20px;
    margin: 0px;
    border-radius: 5px;
    list-style: none;
    min-width: 80%;
    text-decoration: none;
    align-self: center;
  }

  .removal{
margin-bottom: 10px;
align-self: flex-start;
  }
  
  .itemimages{
  max-width: 100px;
  max-height: 100px;
  min-width: 100px;
  min-height: 100px;
  }

  @media (max-width: 500px) {
    .addup{
      position: fixed;
  
      bottom: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      max-width: 70% !important;
      min-width: 70% !important;
      box-sizing: border-box;
      padding: 10px;
      
      }
  }

  @media (max-width: 768px){
    .addup{
      position: fixed;
      bottom: 0px;
     
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      max-width: 60%;
      min-width: 60%;
      box-sizing: border-box;
      padding: 10px;
      
      }
  }

 
  

  
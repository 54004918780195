body{
margin: 0px;
padding: 0px;
}

.confirmation-container {
    text-align: center;
    padding: 20px;
    min-height: 70vh;
  }

  .customeritems{
gap: 30px;
box-sizing: border-box;
padding: 10px;
margin-bottom: 20px;
border-radius: 5px;
display: flex;
max-width: 100%;
min-width: 100%;
box-shadow: 10px 10px 20px 10px rgba(128, 128, 128, 0.106);
overflow: scroll;
  }

  .clientcontact{
  font-weight: bold;
  }
  
  
  .confirmation-container h2 {
    color: blue; /* Success color */
  }
  
  .confirmation-container h3 {
    margin-top: 20px;
  }
  

.checkcont{
box-sizing: border-box;
margin: 29px;

display: flex;
border-radius: 5px;
flex-direction: column;
box-sizing: border-box;
padding: 40px;
align-items: flex-start;
justify-content: center;
color: #0A3C86;
}


.boxdiv{

    box-sizing: border-box;
    padding: 40px;
    box-shadow: 10px 10px 20px 10px rgba(128, 128, 128, 0.106);
    display: flex;

    justify-content: center;
    align-items: center;
    border-radius: 5px;
    height: 400px;
    width: 90vw;
    margin-bottom: 10px;
}

.boxdivit{
display: flex;
}

.boxprice{
font-weight: bold;
}

.boxname{
font-size: 25px;
}

.boxdet{
display: flex;
align-items: flex-start;
flex-direction: column;
margin-left: 20px;
min-height: 100%;

justify-content: space-around;
}

.chekedimage{
height: 200px;
width: 200px;
}

.checkcont
form{
box-sizing: border-box;
padding: 0px;
display: flex;
flex-direction: column;
max-width: 100%;
min-width: 100%;
padding-right: 800px;
padding-left: 40px;
padding-top: 40px;
padding-bottom: 40px;
gap: 20px;
border-radius: 5px;

box-shadow: 5px 10px 20px 10px rgba(89, 100, 199, 0.119);
}

.checkoutLit{
align-self: flex-start;
padding: 20px;
background-color: #1650A6;
}

.totalsht{
background-color: rgb(255, 255, 255);
border-radius: 4px;
padding: 0px;
color: #4873B4;
box-sizing: border-box;
width: 40%;
margin-bottom: 15px;

}

.checkcont input{
border: solid 1px #1650a696 !important;
border-radius: 5px;
padding: 30px;
width: 100%;
padding: 20px !important;

}

button {
align-self: center;
justify-self: center;
}

@media (max-width: 1300px) {
  .checkcont
  form{
  box-sizing: border-box;
  padding: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  border-radius: 5px;
  padding-right: 200px;
  padding-left: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
  box-shadow: 5px 10px 20px 10px rgba(89, 100, 199, 0.119);
  }
}

@media (max-width: 1000px) {
  .checkcont
  form{
  box-sizing: border-box;
  padding: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  border-radius: 5px;
  padding-right: 100px;
  padding-left: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
  box-shadow: 5px 10px 20px 10px rgba(89, 100, 199, 0.119);
  }

}

@media (max-width: 500px) {


.boxdiv{
    box-shadow: 10px 10px 20px 10px rgba(128, 128, 128, 0.106);
    box-sizing: border-box;
    padding: 40px;
    display: flex;

    justify-content: center;
    align-items: center;
    border-radius: 5px;
    height: auto;
    min-width: 120%;
    margin-bottom: 10px;
}

    .checkcont
    form{
    box-sizing: border-box;
    padding: 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    border-radius: 5px;
    padding-right: 40px;
    padding-left: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
    box-shadow: 5px 10px 20px 10px rgba(89, 100, 199, 0.119);
    }





.checkcont{
    box-sizing: border-box;
    margin: 15px;
    
    display: flex;
    border-radius: 5px;
    flex-direction: column;
    box-sizing: border-box;
    padding: 5px;
    align-items: flex-start;
    justify-content: center;
    color: #0A3C86;
    }
}
/* Blog List Styling */
.blog-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Creates a three-column layout */
    gap: 30px;
    justify-items: center;
    padding: 50px;
    box-sizing: border-box;
    height: fit-content;
    align-items: center;
    margin-bottom: 100px;
  }
  

  article{
display: flex;
min-height: 90vh;
justify-content: flex-start;
box-sizing: border-box;
padding-left: 200px;
padding-right: 200px;
padding-top: 50px;
padding-bottom: 50px;
flex-direction: column;
align-items: flex-start;
  }

  .blogimage{
height: 300px;
min-width: 100%;
object-fit: cover;
  }

  .actualtext{
  box-sizing: border-box;
  }
  
  .blog-item-link {
    text-decoration: none;
    min-width: 100%;

    color: inherit; /* Ensures text color inherits from parent elements, typically black */
  }
  
  .blog-item {
    width: 100%;
    justify-content: space-between ;
    text-align: start;
    align-items: flex-start;
    border-radius: 0px;
    box-sizing: border-box;
    overflow: hidden; /* Ensures images don't overflow the border-radius */
    transition: transform 0.3s ease-in-out; /* Smooth transition for hover effect */
    display: flex;
    min-height: 400px;
    border: 0.5px solid rgba(0, 0, 255, 0.136);
    padding: 20px;
   
    flex-direction: column;
  }
  
  .blog-item:hover {
    transform: translateY(-5px); /* Moves the blog item slightly up on hover */
  }
  
  .blog-image {
    max-width: 100%;
    min-width: 100%;
    height: 200px; /* Fixed height for a uniform appearance */
    object-fit: cover;
    border-radius: 5px; /* Ensures the image covers the area, might crop */
  }
  
  .blog-title {
    padding: 0px; /* Adds space around the title */
    margin-top: 40px;
    color: rgb(64, 64, 254); /* Dark grey/black for readability */
    text-align: start; /* Centers the title */
    font-size: 1.2rem; /* Slightly larger title for emphasis */
    font-weight: normal; /* Adjust based on your design preference */
  }

  .blog-date{
    color: rgba(0, 0, 255, 0.516);
    font-size: 0.8rem;
  }

  @media (max-width: 1000px) {
    .blog-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr); /* Creates a three-column layout */
      gap: 30px;
      justify-items: center;
      padding: 50px;
      box-sizing: border-box;
      height: fit-content;
      align-items: center;
      margin-bottom: 100px;
    }
  }

  @media (max-width: 850px) {
    article{
      display: flex;
      min-height: 90vh;
      justify-content: flex-start;
      box-sizing: border-box;
      padding-left: 50px;
      padding-right: 50px;
      padding-top: 50px;
      padding-bottom: 50px;
      flex-direction: column;
      align-items: flex-start;
        }
  }

  @media (max-width: 700px) {
    .blog-list {
      display: grid;
      grid-template-columns: repeat(1, 1fr); /* Creates a three-column layout */
      gap: 30px;
      justify-items: center;
      padding: 50px;
      box-sizing: border-box;
      height: fit-content;
      align-items: center;
      margin-bottom: 100px;
    }

    article{
      display: flex;
      min-height: 90vh;
      justify-content: flex-start;
      box-sizing: border-box;
      padding-left: 50px;
      padding-right: 50px;
      padding-top: 50px;
      padding-bottom: 50px;
      flex-direction: column;
      align-items: flex-start;
        }
  }

  @media (max-width: 500px) { .blog-list {
    display: grid;
    grid-template-columns: repeat(1, 1fr); /* Creates a three-column layout */
    gap: 30px;
    justify-items: center;
    padding: 30px;
    box-sizing: border-box;
    height: fit-content;
    align-items: center;
    margin-bottom: 100px;
  }
}
  
/* src/components/LandingPage.css */

body{
background-color: rgb(255, 255, 255);
color: white;
}

.toplis{
width: 100%;
display: flex;
}

.viddata{
height: 100%;
width: 100%;
}

.bonk{
display: flex;
flex-direction: column;
color: #A9A9A9 !important;
}


.info-page h1{
align-self: flex-start;
font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
color: grey;
font-weight: 300;
}

.ex{
align-self: flex-start;
margin-bottom: 20px;
}

.analysis-page{
border-radius: 5px;
width: 100%;
min-height: 120vh;
color: rgb(169, 169, 169);
display: flex;
margin-top: 0px;
padding-top: 0px;

justify-content: space-between;
padding-bottom: 0px;
padding-left: 0px;
gap: 0px;
padding-right: 0px;
box-sizing: border-box;
}

.ai-content{
background-color: #ffffff;
box-sizing: border-box;
padding: 10px;
border-radius: 10px;
min-height: 30vh;
font-weight: bold;
margin-top: 10px;
}

.ai-content h3{
 font-weight: normal;
 color: rgba(0, 26, 74, 0.66) ;
  }

  .ai-content p{
  max-width: 60ch;
  }

.rehabtitle{
background-color: white;
padding: 15px;
box-shadow: 10px 10px 20px 10px rgba(128, 128, 128, 0.106);
width: 100%;
box-sizing: border-box;
display: flex;
justify-content: center;
color:rgba(0, 26, 74, 0.804);
border-radius: 5px;
font-weight: 500;
}

.sistitle{
background-color: rgb(0, 26, 74) ;
padding-top: 30px;
padding-bottom: 30px;
border-radius: 5px;
font-weight: bold;
text-align: center;
width: 100%;
font-size: 15px;
color: rgb(255, 255, 255);
}

.toptabs{
display: flex;
gap: 15px;
}

.rehabtitle{
width: fit-content
}

.commercial{
margin-top: 0px;
justify-content:flex-start;

display: flex;
background-color: rgba(255, 255, 255, 0);
flex-direction: column;
gap: 15px;
width: 50%;
padding: 50px;
box-sizing: border-box;
}

.tab-button {
  padding-top: 30px;
  color: white ;
  background-color: #4873B4;
  padding-bottom: 30px;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  font-size: 15px;
}

.tab-button:hover {
  background-color:  #0A3C86;
  color: white;
}

.active {
  background-color: #0A3C86;
  color: white;
}








pre{
width: 20ch;
}

.info-page {
    background-color: rgb(0, 0, 0);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .analysis-page h1{
 margin-left: 40PX;
  }

  .ex{
   margin-left: 40px;
  }

  .analysis-page img{
  object-fit: cover;
  height: 200px;
  max-width: 90vw;
  min-width: 90vw;
  overflow: scroll;
  }

  .pagination {
    display: flex;
    justify-content: center;
    
  }
  
  .dot {
    height: 10px;
    width: 10px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }
  


  .textbox{
    margin-top: 100px;
    display: flex;
    width: 100vw;
    overflow: scroll;
    gap: 20px;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
  }

  .textcontainer{
  min-width: 90vw;
  box-sizing: border-box;
  background-color: white;
  color: rgb(144, 144, 144);
  border-radius: 10px;
  padding: 20px;
  height: fit-content;
  min-height: 300px;
  }



  .aicontent{
  width: 70vw;
  height: 200px;
  background-color: white;
  color: black;
  overflow: scroll;
  box-sizing: border-box;
  padding: 20px;
  }

  .counter{
    background-color: white;
    padding: 15px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    justify-content: center;
    color: rgb(0, 26, 74) ;
    border-radius: 5px;
    font-weight: 500;
  }

  ul{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  padding: 0px;
list-style: none;
text-decoration: none;
  }

  .result{
  background-color: white;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: flex-start;
  padding: 50px;
  width: 100%;
  gap: 40px;
  margin-top: 0px;
  pointer-events: all;

  }


@media (max-width: 1000px) {
  .analysis-page{
    border-radius: 5px;
    width: 100%;
    color: rgb(169, 169, 169);
    display: flex;
    margin-top: 0px;
    flex-direction: column;
    box-sizing: border-box;
    }


    .result{
      background-color: white;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding: 20px;
      width: 100%;
      gap: 10px;
      border-right: none;


      }

      .commercial{
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;
        box-sizing: border-box;
        }
}


@media (max-width: 500px) {

  .tab-button {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
    font-weight: bold;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    font-size: 15px;
  }

  .commercial{
    margin-top: 0px;
    justify-content:flex-start;
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    }


}

  

.landing-page {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
    z-index: -100000000000000;
  }

  .termsModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: all;
    background-color: rgba(0, 0, 0, 0.67);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .scrollableTerms {
    max-height: 200px; /* Adjust based on your design */
    overflow-y: auto;
    margin-top: 20px;
    border: 1px solid #ccc; /* Optional, for style */
    padding: 10px;
  }

  .termsModal a{
  text-decoration: underline;
  }
  
  .termsModal {
    background-color: white;
    padding: 20px;
    max-width: 80%;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1001;
  }
  
  .termsModal button {
    margin-top: 20px;
    background-color:#1650A6;
  }

  .termsModal button:hover{
    background-color:#1650a6c4;
  }

  .termsModal{
  color: black !important;
  }

  .typing-container {
    padding: 20px;
    color: #0022ff;
    border-radius: 5px;
    box-sizing: border-box;
    max-width: 100% ;
  }
  
  .typing-text {
    font-size: 15px;
    border-right: 2px solid #333;
    white-space: nowrap;
    color: #4873B4;
    overflow: hidden;
    animation: typing 3s steps(30) 1s infinite alternate;
  }
  
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }

  .landing-page p {
  margin: 0px;
  }



  .input-container{
  width: 60%;
  height: 90vh;
  z-index: 10;
  margin-top: 0px;
  background-color: white;
 align-items: center;
 justify-content: center;
display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 30px;
  gap: 20px;
}

.injuryana{
color: #ffffff ;
background-color: #1650A6;
}

.injuryana:hover{
  background-color: #1650a6d5;
}

.godsplan{
display: flex;
gap:50px; 
flex-direction: column;
box-sizing: border-box;

}

.ting{
display: flex
}

  #unity-logo {
    display: none; /* This attempts to hide the Unity logo */
  }

  #clicked-object-name {
  color: white;
  }

  .upload-icon {
    display: inline-block;
    border: 1px solid #ffffff;
    border-radius: 4px;
    text-align: center;
    line-height: 50px; /* Vertically center the plus sign */
    cursor: pointer;
    font-size: 24px; /* Adjust size as needed */
    background-color: #ffffff00;
    width: 80%;
    height: auto;
    margin-top: 40px;
    cursor: pointer;
  }

  .upload-icon:hover{
  background-color: rgba(255, 255, 255, 0.047);
  }

  .image-preview {
    margin-top: 10px;
    display: flex;
    width: 60px;
  }


  

  
 



  .image-preview-container{
  display: flex;
  align-items: center;
  flex-direction: column;
  }
  

  
  .container {
    text-align: center;
  }
  
  .landing-page input {
    padding: 10px;
    font-size: 16px;
    box-sizing: border-box;
    border-radius: 0px;
    width: 80%;
    color: #ddd;
    height: 55px;
    margin-top: 20px;

  }
  
  button {
    padding: 10px 20px;
    font-size: 18px;
    background-color: rgb(0, 26, 74);
    color: rgb(231, 231, 231);
    border: none;
    border-radius: 4px;
    width: 200px;
    align-self: flex-end;
    margin-bottom: 40px;
    cursor: pointer;
    margin-top: 30px;

  }


 


  #fileInput{
  display: none;
  }
  
  button:hover {
    background-color: #a9a9a9;
  }

  #clicked-object-name{
  margin-top: 20px;
  gap: 20px;
  margin-bottom: 20px;
  display: flex;
  max-width: 80%;
  justify-content: flex-start;
  min-height:40px;
  }

  .slides{
  width: 100%;
  }

  .pain-page{
    box-sizing: border-box;
    height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  }
  
  .diagnose{
  height: 100px;
  width: 100%;
  color: #4873B4;
  border-radius: 4px;
  box-shadow: 0px 0px 20px 12px #4873b401;
  background-color: #4873b419 !important;
  border: none;
  padding: 10px;
  background-color: white;
  box-sizing: border-box;
  gap: 20px;
  font-size: 25px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .diagnose::placeholder{
  color: #4873b44b;
  font-size: 25px;
  }

  #unity-canvas {
    width: 50%;     /* Fixed width */
    height: 70vh;
    margin-top: 20px;
    z-index: 1;
    display: block;  /* Prevent inline default behavior */
    background-color: #ffffff;
}


.landing-page {
    overflow: auto; /* Adds scrollbar if content overflows */
    width: 100%;    /* Adjust as needed */
    height: 100%;   /* Adjust as needed */
}


.optionBox{

display: flex;
align-items: flex-start;
flex-direction: column;
margin-top: 0px;
padding: 20px;
border-radius: 4px;
gap: 30px;
box-sizing: border-box;
color:#011752;
width: 50%;
}

.slider-container{
display: flex;
flex-direction: column;
width: 100%;
gap: 15px;
margin-bottom: 30px;
}

.slider-container span{
color: #4873B4;
font-weight: bold;
}

.radio-container {
  display: flex; /* Change to block to stack radio buttons vertically */
  position: relative;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;

 /* Adjust this to control the spacing between the circle and label */
  margin-bottom: 10px; /* Add margin to separate radio buttons */
}







  .object-name{
  color: #4873B4;
  padding: 10px;
  background-color: #4873b416 ;
  box-shadow: 0px 0px 10px 5px #4873b403;
  border: none ;
  border-radius: 5px;
  position: relative;
  overflow: visible;
  }

  .removeButton{
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -15px;
  z-index: 1000000000000000000000;
  right: -15px;
  margin-top: 0px;
  background-color: #011752c0;
  margin-bottom: 0px;
  padding: 15px;
  }

  .custom-loader {
    position: fixed; /* or 'absolute' if your canvas is positioned relative to a specific container */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ffffff; /* Or any other color or image */
    color: #727272; /* Text color */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Make sure this is above your Unity canvas */
    font-size: 1.5em;
    transition: opacity 0.5s ease, visibility 0.5s ease;
    visibility: visible;
    opacity: 1;
  }

  .func{
  background-color: white;
  width: fit-content;
  padding: 0px;

  }

  .dis{
  color: #0A3C86;
  font-weight: 400;
  }

/* General styles for the slider */
/* General styles for the slider */
input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
  cursor: pointer;
  margin: 10px 0;
  transition: background-color 0.3s ease; /* Smooth transition for the track */
}

/* Styles for the slider track */
input[type=range]::-webkit-slider-runnable-track {
  background: #0a3c864f;
  border:none;
  height: 12px;
  border-radius: 5px;
  transition: background-color 0.3s ease; /* Smooth background color transition */
}

input[type=range]::-moz-range-track {
  background: #0A3C86;
  height: 12px;
  border-radius: 6px;
  transition: background-color 0.3s ease;
}

input[type=range]::-ms-track {
  background: #0A3C86;
  height: 12px;
  border-radius: 6px;
  border: none;
  color: transparent;
  transition: background-color 0.3s ease;
}

/* Styles for the slider thumb */
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 0px solid #0A3C86;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #0A3C86;
  margin-top: -6px;
  transition: border-color 0.3s ease, background-color 0.3s ease, transform 0.3s ease; /* Smooth transition for the thumb */
}

input[type=range]::-moz-range-thumb {
  border: 0px solid #0A3C86;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #0A3C86;
  transition: border-color 0.3s ease, background-color 0.3s ease, transform 0.3s ease;
}

input[type=range]::-ms-thumb {
  border: 0px solid #0A3C86;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #0A3C86;
  transition: border-color 0.3s ease, background-color 0.3s ease, transform 0.3s ease;
}

/* Hover state styles */
input[type=range]:hover::-webkit-slider-thumb {
  background: #0A3C86;
  transform: scale(1.1); /* Slightly larger thumb on hover */
}

input[type=range]:hover::-moz-range-thumb {
  background: #0A3C86;
  transform: scale(1.1);
}

input[type=range]:hover::-ms-thumb {
  background: #0A3C86;
  transform: scale(1.1);
}

/* Active (dragging) state styles */
input[type=range]:active::-webkit-slider-thumb {
  background: #0A3C86;
  transform: scale(1.1); /* Slightly larger thumb on active */
}

input[type=range]:active::-moz-range-thumb {
  background: #0A3C86;
  transform: scale(1.1);
}

input[type=range]:active::-ms-thumb {
  background:#0A3C86;
  transform: scale(1.1);
}

/* Focus state styles for better accessibility */
input[type=range]:focus {
  outline: none;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #0a3c864f;
}

input[type=range]:focus::-moz-range-track {
  background: #0A3C86;
}

input[type=range]:focus::-ms-track {
  background: #0A3C86;
}

.radio-container input[type="radio"] {
  display: none;
}

/* Create the circular shape for the radio button */
.radio-container .radio-button {

cursor: pointer;
height: 20px; /* Adjust this to control the size of the circle */
  width: 20px; /* Should be the same as the height for a perfect circle */
  border: 2px solid #0A3C86; /* Blue border */
  border-radius: 50%; /* Makes it circular */
}

/* Style the radio button when it's selected (filled with blue) */
.radio-container input[type="radio"]:checked + .radio-button {
  background-color: #0A3C86; /* Fill with blue when selected */
}

.raded label{
display: flex;
flex-direction: column;
gap: 10px;
justify-content: center;
align-items: center;
}






@media (max-width: 768px) {
  .landing-page {
    background-color: rgb(255, 255, 255);
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 0px;
  }


  #unity-canvas {
    width: 100%;   /* Fixed width width: 960px; */
    height: 65vh;
    z-index: 1;  /* Fixed height */
    display: block; /* Prevent inline default behavior */
    background-color: #ffffff;
    margin-top: 10px;

    overflow: hidden;
}

  .input-container {
    order: 2; /* This will now appear second */
  }


  .input-container{
    width: 100%;
    height: 90vh;
    box-shadow: 10px 4px 8px 4px rgba(14, 21, 55, 0.102);
    z-index: 10;
    background-color: white;
  display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 30px;
    gap: 20px;
  }
}

@media (max-width: 500px){




  .diagnose{
    height: 100px;
    width: 100%;
    color: #4873B4;
    border-radius: 4px;
    border: none;
    padding: 5px;
    box-shadow: 0px 0px 20px 12px #4873b401;
    background-color: white;
    box-sizing: border-box;
    gap: 20px;
    font-size: 19px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    .diagnose::placeholder{
      color: #4873b44b;
      font-size: 19px;
      }

  .optionBox{

    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 0px;
    padding: 20px;
    border-radius: 4px;
    gap: 30px;
    box-sizing: border-box;
    color:#011752;
    width: 90%;
    }


  .object-name{
    color: #4873B4;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4873b416 ;
    box-shadow: 0px 0px 10px 5px #4873b403;
    font-size: 7px;
    border: none;
    border-radius: 5px;
    }





  #unity-canvas {
    width: 100%;     /* Fixed width */
    height: 55vh;
    z-index: 1;
    margin-top: 10px;
    display: block;  /* Prevent inline default behavior */
    background-color: #ffffff;
}


.radio-container{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 30px;
  }




}

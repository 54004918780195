/* ProgressSpinner.css */
.progress-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    flex-direction: column;
  }


  @keyframes pinner {
    to {transform: rotate(360deg);}
  }
  
  .pinner {
    border: 4px solid #00219944;
    border-radius: 50%;
    border-top-color: #002199;
    height: 50px;
    width: 50px;
    animation: pinner 1s ease-in-out infinite;
  }
  
  .containerpinner{
    height: 90vh;
    width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  }

  .progress-spinner h2{
color: rgba(0, 26, 74, 0.605);
font-weight: lighter;
  }
  
  .progress-ring {
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
  
  .progress-ring__circle {
    transition: stroke-dashoffset 0.35s;
    stroke-linecap: round;
  }
  
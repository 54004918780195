.model-view-page{
color: black;
display: flex;
align-items: center;
min-height: 90vh;
flex-direction: column;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}




.popup-content {
  background: #ffffff;
  border-radius: 8px;
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 500px;
  z-index: 9999;
}

.popup-close {
  float: right;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  background-color: #1650A6;
}

.popup-close:hover{
  background-color: #1650a6db;
}

.popup-image {
  max-width: 60%;
  height: auto;
  margin-bottom: 20px;
}

.popup-text {
  font-size: 1rem;
  color: #1650A6;
}


@keyframes pinner {
  to {transform: rotate(360deg);}
}

.backdrop{
position: absolute;
opacity: 0.5;
right: 583px;
}

.pinner {
  border: 4px solid #0a3c8653;
  border-radius: 50%;
  border-top-color: #0A3C86;
  height: 50px;
  width: 50px;
  animation: pinner 1s ease-in-out infinite;
}

.containerpinner{
  height: 90vh;
  width: 100%;
display: flex;
flex-direction: column;
gap: 50px;
color: #0A3C86;
align-items: center;
justify-content: center;
}

.modelnext{
color: #ffffff ;
background-color: #1650A6;
position: absolute;
bottom: 30px;
right: 50px;
}

.modelnext:hover{
  background-color: #1650a6d6;
}

.instruct {
    position: absolute;
    color: #4873B4;
    background-color:#4873b416;
padding: 10px;
border-radius: 5px;
    top: 150px;
    z-index: 999;
    left: 50%; /* Center the element from the left side of the screen */
    transform: translateX(-50%); /* Shift it back by half its own width */
  }



.ting{
align-items: center;
display: flex;
justify-content: center;
}

@media (max-width: 500px){
  .modelnext{
    color: #ffffff ;
    background-color: #1650A6;
    position: absolute;
    bottom: 0px;
    right: 5px;
    }


.instruct {
  position: absolute;
  color: #4873B4;
  background-color: #4873b416;
padding: 10px;
font-size: 13px;
border-radius: 5px;
  top: 150px;
  z-index: 999;
  left: 50%; /* Center the element from the left side of the screen */
  transform: translateX(-50%); /* Shift it back by half its own width */
}
}